import { useOutsideClick } from '@chakra-ui/react';
import { useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { BsChatLeftText } from 'react-icons/bs';
import { MdClose, MdMenu } from 'react-icons/md';
import { Link, NavLink, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { showTalent } from 'utils/talents.util';
import config from '../../../config';
import { useSocketContext } from '../../../context/SocketContext';
import useBoolean from '../../../hooks/useBoolean';
import { AuthMenu } from './components/AuthMenu';
import Idiom from './components/Idiom';
import Incubate from './components/Incubate';
import Notification from './components/Notification';
import './navbar.scss';

const Img = styled.img`
  width: ${(props) => props.width || '120px'};
`;

const entityID = process.env.REACT_APP_ID_ENTITY;

const Navbar = ({ user }) => {
  const { messages } = useSocketContext();
  const { t } = useTranslation();
  const isVisible = useMemo(
    () =>
      [
        107, 2241, 633, 226, 71, 4193, 3403, 7859, 7851, 8470, 8660, 2055, 8836, 8777, 149, 4755, 5665, 6341, 3612,
        2647, 9049,
      ].includes(Number(process.env.REACT_APP_ID_ENTITY)),
    [],
  );
  const { isOpen, onToggle, onClose } = useBoolean();
  const location = useLocation();

  if (entityID == 4193) {
    return (
      <Incubate isOpen={isOpen} onToggle={onToggle} t={t} messages={messages} user={user} config={config}></Incubate>
    );
  }

  const ref = useRef();
  useOutsideClick({
    ref: ref,
    handler: () => onClose(),
  });

  useEffect(() => {
    onClose();
  }, [location]);

  return (
    <nav className="navbar-app navbar-expand-xl navbar-light bg-light p-2">
      <div className={`collapse   align-items-center navbar-collapse mr-auto  ${isOpen && 'show'} md-show `} ref={ref}>
        <ul className="navbar-nav bg-light flex-xl-row mr-auto p-4 p-lg-2">
          {/* Public Links */}
          <li className="nav-item my-2 my-xl-0">
            <NavLink exact activeClassName="selected inicio-reactour" className="newStyle" to="/">
              {t('header.header_msg')}
            </NavLink>
          </li>
          {/* Authenticated links */}
          {user && config.has_hours && (
            <li className="nav-item my-2 my-xl-0">
              <NavLink activeClassName="selected" className="horas-reactour newStyle" to="/horas">
                {t('header.header_msg3')}
              </NavLink>
            </li>
          )}
          <div className="border-top"></div>
          {isVisible && (
            <li className="nav-item my-2 my-xl-0">
              <NavLink activeClassName="selected" className="horas-reactour newStyle" to="/community">
                {config.profile_globant ? t('community.title') : t('header.header_msg8')}
              </NavLink>
            </li>
          )}
          {showTalent(user) && (
            <li className="nav-item my-2 my-xl-0">
              <NavLink activeClassName="selected" className="horas-reactour newStyle" to="/talents">
                {t('header.talents')}
              </NavLink>
            </li>
          )}
          <div className="border-top"></div>
          {user && (
            <li className="nav-item my-2 my-xl-0">
              <NavLink activeClassName="selected" className="act-reactour newStyle" to="/actividad">
                {t('header.header_msg2')}
              </NavLink>
            </li>
          )}
          {!config.profile_globant && user && (
            <li className="nav-item d-xl-none my-2">
              <NavLink activeClassName="selected" className="chat-reactour newStyle" to="/chat">
                Chat
              </NavLink>
            </li>
          )}
          <div className="border-top"></div>

          <li className="nav-item d-xl-none">{config.translation && <Idiom />}</li>
        </ul>
      </div>
      {/* Logo */}
      <div className="logo navbar-brand m-auto ">
        <Link to="/">
          <Img
            src={config.logo}
            width={config.logo_width}
            className="d-inline-block align-top logo-reactour"
            alt="logo"
          />
        </Link>
      </div>
      <ul className="navbar-nav    flex-row align-items-center justify-content-end">
        {/* Chat link */}
        {user && (
          <>
            {!config.profile_globant && (
              <li className="nav-item d-none mb-xl-1 d-xl-block">
                <Link to="/chat" className="navbar-brand p-0">
                  <button type="button" className="btn btn-ghost btn-sm p-0">
                    <BsChatLeftText size={18} />
                    {!!messages.length && (
                      <>
                        <span className="badge badge-primary">{messages.length}</span>
                        <span className="sr-only">mensajes sin leer</span>
                      </>
                    )}
                  </button>
                </Link>
              </li>
            )}
            <li className="nav-item flex">
              <Notification />
            </li>
          </>
        )}
        <li className="nav-item">
          <AuthMenu />
        </li>
        <li className="nav-item  d-none d-xl-block">{config.translation && <Idiom />}</li>
        {/* Responsive Menu */}
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          aria-label="Toggle navigation"
          onClick={onToggle}
          style={{ padding: '5px' }}
        >
          {isOpen ? <MdClose /> : <MdMenu />}
        </button>
      </ul>
    </nav>
  );
};

export default Navbar;
